<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="logo"
          alt="Logo"
          class="desktop-logo"
        />
      </b-link>

      <!-- Forget Password-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-center">
            <b-img
              fluid
              :src="logo"
              alt="Logo"
              style="margin-bottom: 20px"
              class="mobile-logo"
            />
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Nova senha
          </b-card-title>
          <b-card-text class="mb-2">
            <b>Redefinição de senha:</b> defina uma nova senha para ter acesso à nossa plataforma.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validationForm()"
            >
              <!-- password -->
              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Senha"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:6|verify_password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- repeat password -->
              <b-form-group
                label-for="register-repeat_password"
                label="Repetir senha"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="repeat_password"
                  vid="repeat_password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-repeat_password"
                      v-model="repeat_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-repeat_password"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Enviar
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      repeat_password: '',
      password: '',
      required,
      logo: [],
      banner: [],
      logoPreview: null,
      bannerPreview: null,
      name: '',
      description: '',
      isBusy: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getPersonalizacao() {
      const logoLocalStorage = localStorage.getItem('logo');
      const bannerLocalStorage = localStorage.getItem('banner');
      const titleLocalStorage = localStorage.getItem('title');
      const descriptionLocalStorage = localStorage.getItem('description');
      let requestGetOn = false

      if (typeof logoLocalStorage === 'undefined' || logoLocalStorage === null || logoLocalStorage.includes('undefined') || logoLocalStorage.includes('null')) {
        this.logo = require('@/assets/default/logo.png')
        requestGetOn = true
      } else {
        this.logo = logoLocalStorage
      }

      if (typeof bannerLocalStorage === 'undefined' || bannerLocalStorage === null || bannerLocalStorage.includes('undefined') || bannerLocalStorage.includes('null')) {
        this.banner = require('@/assets/default/banner.png')
        requestGetOn = true
      } else {
        this.banner = bannerLocalStorage
      }

      if (typeof titleLocalStorage === 'undefined' || titleLocalStorage === null || titleLocalStorage.includes('undefined') || titleLocalStorage.includes('null')) {
        this.title = 'Plataforma OP3'
        requestGetOn = true
      } else {
        this.title = titleLocalStorage
      }

      if (typeof descriptionLocalStorage === 'undefined' || descriptionLocalStorage === null || descriptionLocalStorage.includes('undefined') || descriptionLocalStorage.includes('null')) {
        this.description = 'uma plataforma completa para transmissões ao vivo, gravação, gestão e controle de cursos'
        requestGetOn = true
      } else {
        this.description = descriptionLocalStorage
      }

      if(requestGetOn == true){
        axios.get(`${process.env.VUE_APP_API}/personalizar`)
          .then(response => {
            if(response.data.response.logo != null && response.data.response.logo != 'undefined' && !response.data.response.logo.includes('undefined') && !response.data.response.logo.includes('null')){
              this.logo = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`
              localStorage.setItem('logo', this.logo);

            }
            if(response.data.response.banner != null && response.data.response.banner != 'undefined' && !response.data.response.banner.includes('undefined') && !response.data.response.banner.includes('null')){
              this.banner = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`
              localStorage.setItem('banner', this.banner);

            }
            if(response.data.response.name != null && response.data.response.name != 'undefined' && !response.data.response.name.includes('undefined') && !response.data.response.name.includes('null')){
              this.title = `${response.data.response.name}`
              localStorage.setItem('title', this.title);

            }
            if(response.data.response.description != null && response.data.response.description != 'undefined' && !response.data.response.description.includes('undefined') && !response.data.response.description.includes('null')){
              this.description = `${response.data.response.description}`
              localStorage.setItem('description', this.description);

            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.resetPassword()
        }
      })
    },
    async resetPassword() {
      const resetPassword = await useJwt.resetPassword({
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.password,
        password_confirmation: this.repeat_password
      })
      if (resetPassword.data.status === 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: resetPassword.data.message,
            icon: 'EditIcon',
            variant: 'success',
          },
        })

        this.$router.push({ path: '/login' })
      } else if (resetPassword.data.status === 400) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: resetPassword.data.message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email ou senha inválidos',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    }
  },
  beforeMount() {
    this.getPersonalizacao()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@media (min-width: 0px) and (max-width: 991px) {
  .mobile-logo {
    display: block;
  }
  .desktop-logo {
    display: none;
  }
}
@media (min-width: 992px){
  .desktop-logo {
    display: block;
  }
  .mobile-logo {
    display: none;
  }
}
.mb-2, .mb-1 {
  text-align: center;
}
</style>
